//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    props: ['dataset'],
    data() {
        return {
            first_name: this.dataset.first_name,
            last_name: this.dataset.last_name,
            nickname: this.dataset.nickname
        }
    },
    components: {
        ActionButtons
    },
    computed: {

        // The script to determine whether to unlock the save button.
        enableSave() {
            if (this.first_name != this.dataset.first_name) {
                return false;
            }
            if (this.last_name != this.dataset.last_name) {
                return false;
            }
            if (this.nickname != this.dataset.nickname) {
                return false;
            }
            
            return true;
        }
    },
    methods: {
        reset() {
            this.first_name = this.dataset.first_name,
            this.last_name = this.dataset.last_name,
            this.nickname = this.dataset.nickname
        }
       
    }
}
